import React from 'react'
import {observer} from 'mobx-react'
import {Dialog} from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import cross from '../../assets/images/common/cross_dark.svg'
import IconButton from '@mui/material/IconButton'
import {getStyles} from 'src/utils/utils/commonUtils'
import customDialogStyles from './customDialogStyles'

function CustomDialog({
    open,
    onClose,
    children,
    title,
    styles = {},
    customTopPanel = false,
    hideBackdrop = false,
    smallTitle,
}) {
    const classes = getStyles(customDialogStyles)

    return (
        <Dialog
            open={!!open}
            onClose={onClose}
            classes={{
                root: classes.root(styles),
            }}
            hideBackdrop={hideBackdrop}
            sx={{
                right: hideBackdrop ? '380px' : '0',
                '& .MuiDialog-container': {
                    width: hideBackdrop ? 'fit-content' : '100%',
                },
            }}
            // disableAutoFocus={hideBackdrop}
            disableAutoFocus={true} // Отключает автофокус
            disableEnforceFocus={true} // Отключает принудительный фокус
            disableRestoreFocus={true}
            
        >
            {customTopPanel ? null : (
                <Box className={'jst-sb aln-center'}>
                    <Typography variant={smallTitle ? 'subtitle1' : 'h1'}>{title}</Typography>
                    <IconButton onClick={onClose} sx={{margin: '0 0 0 8px', padding: 0}}>
                        <img alt='' src={cross} />
                    </IconButton>
                </Box>
            )}
            {children}
        </Dialog>
    )
}

export default observer(CustomDialog)
