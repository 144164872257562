import React, {useContext, useMemo} from "react"
import {observer} from "mobx-react"
import {OperationalContext} from "../context/withOperationalStore"
import {useIntl} from "react-intl"
import MenuItem from "@mui/material/MenuItem"
import moment from "moment"
import NewFilters from "../../../components/NewFilters/NewFilters"
import OperationalFilterChip from "./OperationFilterChip"
import downloadWhite from "../../../assets/images/common/downloadWhite.svg"
import downloadGray from "../../../assets/images/common/downloadGray.svg"

const OperationFilters = () => {
    const {
        getStatistic,
        categoriesStore,
        filterStore,
        dealersStore,
        territoryStore,
        loadFile,
        filteredCollection
    } = useContext(OperationalContext)
    const intl = useIntl()
    const filtersData = useMemo(() => {
        return [[
            {
                type: "select",
                propName: "territory",
                name: intl.formatMessage({id: "Регион"}),
                collection: [...territoryStore.collection, intl.formatMessage({id: "Сводный по регионам"})]
            }, [
                {
                    type: "date",
                    propName: "from",
                    name: intl.formatMessage({id: "Дата начала периода"}),
                    minDate: moment(window.SAT.config.minDateOperationalSummary).utc().format("YYYY-MM-DD HH:mm"),
                    maxDate: moment().utc(true).format("YYYY-MM-DD HH:mm")
                },
                {
                    type: "date",
                    propName: "to",
                    name: intl.formatMessage({id: "Дата окончания периода"}),
                    minDate: moment(window.SAT.config.minDateOperationalSummary).utc().format("YYYY-MM-DD HH:mm"),
                    maxDate: moment().utc(true).format("YYYY-MM-DD HH:mm")
                }],
            {
                type: "select",
                multiple: true,
                propName: "categories",
                collection: categoriesStore.collection.map((category) => {
                    return {value: category.id, key: category.name}
                }),
                name: intl.formatMessage({id: "Льготные категории"}),
            },
            {
                type: "text",
                propName: "reason",
                name: intl.formatMessage({id: "Причина"}),
            },
            {
                type: "select",
                name: intl.formatMessage({id: "Дилер"}),
                propName: "dealerGuid",
                collection: dealersStore.collection.map(dealer => {
                    return {value: dealer.subjectId, key: dealer.displayName}
                }),
                setMenuItem: (el) => {
                    return (
                        <MenuItem value={el.value}>
                            {el.key}
                        </MenuItem>
                    )
                }
            },
        ]]
    }, [categoriesStore.collection, dealersStore.collection, intl, territoryStore.collection])

    const handleDeleteChip = (propName, multiselectValue) => {
        filterStore.deleteFilter(propName, multiselectValue)
        getStatistic()
    }

    const setChip = (params, withAction) => <OperationalFilterChip {...params}
                                                                   onClick={withAction && handleDeleteChip}/>

    return (
        <NewFilters
            title={intl.formatMessage({id: "Результаты поиска"})}
            filtersStore={filterStore}
            onSearch={getStatistic}
            filtersData={filtersData}
            setChip={setChip}
            actionButtonProps={{
                name: intl.formatMessage({id: "Сохранить в файл"}),
                disabled: !filteredCollection.length,
                onClick: loadFile,
                icon: filteredCollection.length ? downloadWhite : downloadGray
            }}
        />
    )
}

export default observer(OperationFilters)