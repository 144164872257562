import React, {useState} from "react"
import {observer} from "mobx-react"
import OperationalStore from "src/views/OperationalSummary/stores/OperationalStore"

export const OperationalContext = React.createContext({})

const WithOperationalStore = observer(({children}) => {
    const [operationalStore] = useState(new OperationalStore())

    return (
        <OperationalContext.Provider value={operationalStore}>
            {children}
        </OperationalContext.Provider>
    )
})

export function withOperationalStore(Component) {
    return function withOperationalStoreComponent(props) {
        return (
            <WithOperationalStore>
                <Component {...props}/>
            </WithOperationalStore>
        )
    }
}
