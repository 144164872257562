import FormStore from "../../../utils/stores/FormStore"

const initState = {
    from: null,
    to: null,
    categories: [],
    territory: "",
    reason: "",
    dealerGuid: "",
}

const validateData = {
    territory: {required: true}
}

class OperationFiltersStore extends FormStore {
    constructor() {
        super({initState, validateData})
        this.dateNames = [["from", "to"]]
    }

    clear = () => {
        this.setData(initState)
        this.clearSelectedFilters()
    }
}

export default OperationFiltersStore