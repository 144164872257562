import React, {useEffect} from "react"
import withWidth, {isWidthUp} from "@mui/material/Hidden/withWidth"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import OperationFilters from "./components/OperationFilters"
import OperationTable from "./components/OperationTable"
import {withOperationalStore} from "./context/withOperationalStore"
import {disposeModal, regModal} from "../../utils/controllers/ModalController"
import OperationSettingsDialog from "./components/OperationSettingsDialog/OperationSettingsDialog"
import {observer} from "mobx-react"

const OperationalSummary = ({width}) => {
    useEffect(() => {
        const modals = ["operational-settings"]
        modals.forEach((modal) => {
            regModal(modal)
        })
        return () => {
            modals.forEach((modal) => {
                disposeModal(modal)
            })
        }
    }, [])

    return (
        <>
            <Stack
                direction={"column"}
                className={isWidthUp("md", width) && "aln-center jst-sb"}
                width={1}
                spacing={3}
                sx={{marginBottom: "30px"}}
            >
                <Box width={1}>
                    <Box mb={2}>
                        <OperationFilters />
                    </Box>
                    <OperationTable />
                </Box>
                <OperationSettingsDialog />
            </Stack>
        </>
    )
}

export default withWidth()(withOperationalStore(observer(OperationalSummary)))
