import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react'
import { OperationalContext } from '../context/withOperationalStore'
import CustomTable from '../../../utils/components/CustomTable/CustomTable'
import { useIntl } from 'react-intl'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import tooltipIcon from '../../../assets/images/common/tooltipIcon.svg'
import { openModalFn } from '../../../utils/controllers/ModalController'
import { useTheme } from '@emotion/react'

const OperationTable = () => {
    const {
        filteredCollection,
        loadPageAction,
        onPageSizeChange,
        rightOrderColumns,
        allStatuses,
        settingActionIndicator,
        actualizeSettings,
    } = useContext(OperationalContext)
    const theme = useTheme()
    const intl = useIntl()

    useEffect(() => {
        actualizeSettings()
    }, [])

    const columns = useMemo(() => {
        const names = {
            rowName: intl.formatMessage({ id: 'Регион / МО или ГО' }),
            total: intl.formatMessage({ id: 'По выбранным статусам' }),
        }
        return ['rowName', ...rightOrderColumns].map((column, i) => {
            const statusData = allStatuses.find((status) => {
                return status.id === column
            })
            return {
                propName: i,
                field: column,
                headerElement: (
                    <Box
                        key={column + 'column'}
                        className={'fx-center fx-nowrap'}
                        sx={{
                            minWidth: '120px',
                            width: '100%',
                            justifyContent: i === 0 ? 'flex-start' : null, // заголовок первого столбца выровнен по левому краю
                            [theme.breakpoints.up('1870')]: {
                                paddingLeft: '13px', // на больших экранах поправка отступа для выравнивания по контенту столбца
                            },
                        }}
                    >
                        <img alt={''} src={statusData?.imageSrc} style={{ height: '20px' }} />
                        <Typography
                            variant={'subtitle1'}
                            sx={{
                                fontSize: '13px',
                                fontWeight: 500,
                                textAlign: 'center',
                                margin: '0 4px 0 8px',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {statusData?.name ? statusData.name : names[column]}
                        </Typography>
                        {column !== 'rowName' ? (
                            <Tooltip title={statusData.summaryTooltip} placement={'top-start'}>
                                <img alt={''} src={tooltipIcon} />
                            </Tooltip>
                        ) : null}
                    </Box>
                ),
                renderCell: (params) => {
                    if (column === 'rowName') {
                        return (
                            <Tooltip title={params.rowName} placement={'top-start'} TransitionProps={{ timeout: 0 }}>
                                <Typography
                                    key={column + 'row' + params.rowName + i}
                                    variant={'body1'}
                                    sx={{
                                        width: '100%',
                                        textAlign: 'left',
                                        fontWeight: '600',
                                        height: 'max-content',
                                        maxWidth: '350px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        padding: '4px 0',
                                        cursor: 'default',
                                    }}
                                >
                                    {params.rowName}
                                </Typography>
                            </Tooltip>
                        )
                    }
                    if (column === 'total') {
                        return (
                            <Typography
                                key={column + 'row' + params.total + i}
                                variant={'body1'}
                                className={'fx-center'}
                                sx={{ width: '100%', height: '100%', textAlign: 'center' }}
                            >
                                {params.total}
                            </Typography>
                        )
                    }
                    return (
                        <Typography
                            key={column + 'row' + params[column] ? params[column] + i : 'RowValue' + i}
                            variant={'body1'}
                            className={'fx-center'}
                            sx={{ width: '100%', height: '100%', textAlign: 'center' }}
                        >
                            {params[column] ? params[column] : 0}
                        </Typography>
                    )
                },
            }
        })
    }, [allStatuses, intl, settingActionIndicator])

    const onPageChange = useCallback(
        (event, page) => {
            if (page >= 0) {
                loadPageAction(page)
            }
        },
        [loadPageAction]
    )

    useEffect(() => {
        return onPageChange(0, 0)
    }, [onPageChange])

    const openSettings = () => {
        openModalFn['operational-settings']()
    }

    return (
        <CustomTable
            showPagination={false}
            columns={columns}
            rows={filteredCollection}
            total={filteredCollection.length}
            handleSettings={openSettings}
            noContentText={
                <Typography sx={{ fontWeight: '500', color: '#A9A9A9', fontSize: '13px' }}>
                    {intl.formatMessage({ id: 'выберите параметры для отображения данных' })}
                </Typography>
            }
        />
    )
}

export default observer(OperationTable)
