import {makeAutoObservable} from "mobx"
import OperationFiltersStore from "./OperationFiltersStore"
import {getComp} from "../../../utils/utils/DI"
import {statisticForRegion} from "../../../services/ApiService"
import {closeModalFn, openModalFn} from "../../../utils/controllers/ModalController"
import {gState} from "../../../utils/controllers/GlobalStateController"
import moment from "moment"

class OperationalStore {
    constructor() {
        this.filterStore = new OperationFiltersStore()
        this.territoryStore = getComp("TerritoryStore")
        this.categoriesStore = getComp("CategoriesStore")
        this.dealersStore = getComp("DealersStore")
        this.statusesStore = getComp("StatusesStore")
        this.summaryAllStatuses = [1, 98, 2, 4, 5, 6, 99, 11, 7, 12, 13, 14, 15, 16, 9, 10]
        this.shownTypes = new Set([1, 98, 2, 4, 5, 6, 99, 7])
        this.changeableTypes = new Set([1, 98, 2, 4, 5, 6, 99, 7])
        this.nonFilteredCollection = []
        this.filteredCollection = []
        this.summaryRow = {}
        this.pageSize = 10
        this.pageNumber = 0
        this.summaryForRegions = "Сводный по регионам"
        makeAutoObservable(this)
    }

    get allStatuses() {
        const {summaryStatuses, orderStatuses, reportOrderStatuses} = this.statusesStore
        const statusesWithSummaryData = summaryStatuses.concat(orderStatuses).concat(reportOrderStatuses)
        const reducedObjectWithSummaryData = statusesWithSummaryData.reduce((acc, item) => {
            acc[item.id] = item
            return acc
        }, {})
        return this.summaryAllStatuses.map((status) => reducedObjectWithSummaryData[status])
    }

    // переменная для обозначения ключевых моментов изменения столбцов. Входит в массив зависимостей useMemo
    // значение не важно. Главное ее изменение и последующая перерисовка столбцов таблицы.
    settingActionIndicator = false
    #toggleSettingActionIndicator = () => {
        this.settingActionIndicator = !this.settingActionIndicator
    }

    actualizeSettings = () => {
        if (localStorage.getItem("operationalSettings")) {
            this.changeableTypes = new Set(JSON.parse(localStorage.getItem("operationalSettings")))
            this.shownTypes = new Set(JSON.parse(localStorage.getItem("operationalSettings")))
            this.#toggleSettingActionIndicator()
        }
    }

    get rightOrderColumns() {
        return this.summaryAllStatuses.filter((status) => this.shownTypes.has(status))
    }

    loadFile = () => {
        const intl = gState["intl"]
        const {orderStatuses, reportOrderStatuses, summaryStatuses} = this.statusesStore
        const sortedKeys = ["rowName", ...this.shownTypes]
        const fields = [...this.shownTypes].map((type) => {
            const status = [...orderStatuses, ...reportOrderStatuses, ...summaryStatuses].find((el) => el.id === type)
            return status.name
        })
        fields.unshift(intl.formatMessage({id: "Регион / МО или ГО"}))
        const data = [fields]
        this.filteredCollection.forEach((row) => {
            data.push(
                sortedKeys.map((key) => {
                    if (row[key] !== undefined) {
                        return row[key]
                    }
                    return "-"
                })
            )
        })
        const blobData = data.map((el) => el.join(window.SAT.config.csvParams.divider)).join("\n")
        const encodeData = new TextEncoder("utf-16be").encode(blobData)
        const blob = new File(["\uFEFF", encodeData], intl.formatMessage({id: "Заявки"}), {
            type: `text/csv;charset=utf-8`
        })
        window.location = window.URL.createObjectURL(blob)
    }

    onPageSizeChange = (size) => {
        this.pageSize = size
    }

    loadPageAction = (page) => {
        this.pageNumber = page
    }

    onCheckbox = (id) => {
        if (this.changeableTypes.has(id)) {
            this.changeableTypes.delete(id)
        } else {
            this.changeableTypes.add(id)
        }
    }

    submitSetting = () => {
        this.shownTypes = new Set([...this.changeableTypes])
        this.filterResultByChosenTypes([...this.nonFilteredCollection])
        localStorage.setItem("operationalSettings", JSON.stringify([...this.shownTypes]))
        this.#toggleSettingActionIndicator()
        closeModalFn["operational-settings"]()
    }

    cancelSetting = () => {
        this.changeableTypes = new Set([])
    }

    filterResultByChosenTypes = (arr) => {
        const totalResultRow = {
            total: 0,
            rowName: gState["intl"].formatMessage({id: "ИТОГО (по всем записям)"}),
            totalLast: true
        }
        const filtered = arr.map((row) => {
            const result = {}
            for (const key in row) {
                if (key === "rowName") {
                    result.rowName = row[key]
                    break
                }
                if (this.shownTypes.has(+key)) {
                    result[key] = row[key]
                    totalResultRow[key] = totalResultRow[key]
                        ? (totalResultRow[key] = totalResultRow[key] + row[key])
                        : row[key]
                    // result.total = result.total + row[key]
                    //totalResultRow.total = totalResultRow.total + +(row[key] === 'total' ? 0 : row[key])
                }
            }
            return result
        })

        this.summaryRow = totalResultRow
        this.filteredCollection = [...filtered]
        if (this.filteredCollection.length > 0) {
            this.filteredCollection.push(this.summaryRow)
        }
        this.loadPageAction(0)
    }

    _groupByName = (statisticResult) => {
        return statisticResult.reduce((row, item) => {
            const {nameTerritory, nameDistrict, statusId, total} = item
            const key = nameTerritory + (nameDistrict ? `, ${nameDistrict}` : "")
            row[key] = row[key] ?? {}
            row[key][statusId] = total
            return row
        }, {})
    }

    _singleRequestCallback = (result) => {
        const groupedByName = this._groupByName(result)
        const arrayFromGrouped = Object.entries(groupedByName).map(([key, value]) => {
            return {rowName: key, ...value}
        })
        this.nonFilteredCollection = arrayFromGrouped
        this.filterResultByChosenTypes(arrayFromGrouped)
    }

    getStatistic = () => {
        openModalFn["progress-backdrop"]()
        const data = {}
        for (const key in this.filterStore.data) {
            if (
                this.filterStore.data[key] !== "" &&
                this.filterStore.data[key] !== null &&
                this.filterStore.data[key] !== "Invalid date"
            ) {
                data[key] = this.filterStore.data[key]
                if (key === "from" || key === "to") {
                    data[key] = moment(this.filterStore.data[key]).local(true).format()
                }
            }
        }

        if (data.territory === this.summaryForRegions) {
            delete data.territory
            return statisticForRegion(data)
                .then((result) => {
                    this.filterStore.setSelectedFilters()
                    const groupedByName = result.reduce((row, item) => {
                        const {nameTerritory, statusId, total} = item
                        const key = nameTerritory
                        if (this.territoryStore.collection.includes(key)) {
                            row[key] = row[key] ?? {}
                            row[key][statusId] = row[key][statusId] ? row[key][statusId] + total : total
                        }
                        return row
                    }, {})
                    const arrayFromGrouped = Object.entries(groupedByName).map(([key, value]) => {
                        return {rowName: key, ...value}
                    })
                    this.nonFilteredCollection = arrayFromGrouped
                    this.filterResultByChosenTypes(arrayFromGrouped)
                    closeModalFn["progress-backdrop"]()
                })
                .catch(() => {
                    closeModalFn["progress-backdrop"]()
                })
        }

        return statisticForRegion(data)
            .then((res) => {
                this.filterStore.setSelectedFilters()
                this._singleRequestCallback(res)
                closeModalFn["progress-backdrop"]()
            })
            .catch(() => {
                closeModalFn["progress-backdrop"]()
            })
    }
}

export default OperationalStore
