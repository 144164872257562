import React, { useContext } from 'react';
import CustomDialog from '../../../../components/CustomDialog/CustomDialog';
import { closeModalFn, isModalOpen } from '../../../../utils/controllers/ModalController';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl/lib';
import { observer } from 'mobx-react';
import { getComp } from '../../../../utils/utils/DI';
import DialogItem from './components/DialogItem';
import Button from '@mui/material/Button';
import { OperationalContext } from '../../context/withOperationalStore';
import { IconButton } from '@mui/material';
import cross from 'src/assets/images/common/cross_dark.svg';

const OperationSettingsDialog = () => {
    const intl = useIntl();
    const { cancelSetting, submitSetting, allStatuses } = useContext(OperationalContext);

    return (
        <CustomDialog
            open={isModalOpen['operational-settings']}
            // onClose={() => {
            //     closeModalFn['operational-settings']();
            //     // cancelSetting()
            // }}
            // title={intl.formatMessage({id: "Настроить отчет"})}
            styles={{ width: '650px', padding: '24px 55px' }}
            customTopPanel={true}
        >
            <Box className={'fx-col fx-center'} sx={{ position: 'relative' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        marginBottom: '16px',
                    }}
                >
                    <Typography sx={{ fontWeight: 500, fontSize: '20px' }}>
                        <FormattedMessage id={'Настроить отчет'} />
                    </Typography>
                    <IconButton
                        onClick={() => {
                            closeModalFn['operational-settings']();
                        }}
                        sx={{ position: 'absolute', top: '-19px', right: '-39px' }}
                    >
                        <img alt='' src={cross} />
                    </IconButton>
                </Box>
                <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                    <FormattedMessage id={'Выберите статусы заявок для отображения'} />
                </Typography>
                <Box
                    sx={{
                        display: 'grid',
                        'grid-template-columns': '1fr 1fr',
                        width: '100%',
                        margin: '24px 0 38px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateRows: 'repeat(9, 1fr)',
                            justifyContent: 'space-evenly',
                            gridAutoFlow: 'column',
                            columnGap: '45px',
                        }}
                    >
                        {allStatuses.map((st) => {
                            return <DialogItem statusItem={st} />;
                        })}
                    </Box>
                </Box>
                <Box className={'fx-nowrap'} sx={{ gap: '16px', width: '100%' }}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        fullWidth={true}
                        onClick={() => {
                            submitSetting();
                        }}
                    >
                        <FormattedMessage id={'Применить'} />
                    </Button>

                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        fullWidth={true}
                        onClick={() => {
                            cancelSetting();
                        }}
                    >
                        <FormattedMessage id={'Сбросить'} />
                    </Button>
                </Box>
            </Box>
        </CustomDialog>
    );
};

export default observer(OperationSettingsDialog);
