import {observer} from "mobx-react"
import React, {useCallback} from "react"
import {useIntl} from "react-intl"
import {getComp} from "../../../utils/utils/DI"
import Chip from "../../../components/Chips/Chip"

function OperationalFilterChip({propName, value, onClick}) {
    const intl = useIntl()
    const {getDealerName} = getComp("DealersStore")
    const {getCategoryName} = getComp("CategoriesStore")
    const setChipAction = useCallback(() => {

        return onClick ? () => onClick(propName, value) : null
    }, [value])

    const setValue = useCallback(() => {
        switch (propName) {
            case ("from"): {
                return intl.formatMessage({id: "Период"}) + " " + value
            }
            case ("dealerGuid"): {
                return getDealerName(value)
            }
            case ("categories"): {
                return getCategoryName(value)
            }
            default: {
                return value
            }
        }
    }, [value])

    return <Chip action={setChipAction()} value={setValue()}/>

}

export default observer(OperationalFilterChip)