import React, {useContext} from "react"
import {Checkbox} from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import {observer} from "mobx-react"
import {OperationalContext} from "../../../context/withOperationalStore"
import Tooltip from "@mui/material/Tooltip"
import tooltipIcon from "../../../../../assets/images/common/tooltipIcon.svg"

const DialogItem = ({statusItem}) => {
    const {changeableTypes, onCheckbox} = useContext(OperationalContext)
    return (
        <Box
            key={Math.pow(statusItem.id, 9)}
            className={"fx-nowrap aln-center"}
            sx={{gap: "10px"}}
        >
            <Checkbox
                checked={changeableTypes.has(statusItem.id)}
                onChange={() => onCheckbox(statusItem.id)}
                onClick={e => e.stopPropagation()}
            />
            <Box
                className={"fx-nowrap aln-center"}
                sx={{gap: "4px"}}
            >
                <img alt={""} src={statusItem.imageSrc}/>
                <Typography variant={"body1"} sx={{whiteSpace: "nowrap"}}>
                    {statusItem.name}
                </Typography>

                <Tooltip title={statusItem.summaryTooltip} placement={"top-start"}>
                    <img alt={""} src={tooltipIcon}/>
                </Tooltip>

            </Box>
        </Box>
    )
}

export default observer(DialogItem)