import {css} from "@emotion/css"

const customDialogStyles = (theme) => ({
    root: (styles) => {
        return css({
            "& .MuiDialog-paper": {
                padding: "24px 16px 32px",
                width: styles.width ? styles.width : "100%",
                maxWidth: "100%",
                height: styles.height ? styles.height : "fit-content",
                ...styles
            }
        })
    },
})

export default customDialogStyles
